import React, { FC, memo, ReactNode, ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/core'
import theme from './lib/theme'
import { Auth0Provider } from 'use-auth0-hooks'
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './settings'
import { handleRedirectCallback, handleLoginError, handleAccessTokenError } from './lib/auth'
import { Provider as StoreProvider } from './lib/store'
import Loading from './components/common/Loading'
import { BrowserRouter } from 'react-router-dom'
import Notification from './components/common/Notification'
import ApolloProvider from './lib/ApolloProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
interface ThemeProviderProps {
  children: ReactNode
}

const handleRedirecting = (): React.ReactElement => <Loading />

const Providers: FC<ThemeProviderProps> = ({ children }: ThemeProviderProps): ReactElement => {
  const [origin, setOrigin] = useState<string>()
  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])
  if (!origin) return <Loading />
  return (
    <ThemeProvider theme={theme}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={`${origin}/callback`}
        onRedirectCallback={handleRedirectCallback}
        onLoginError={handleLoginError}
        onAccessTokenError={handleAccessTokenError}
        onRedirecting={handleRedirecting}
      >
        <StoreProvider>
          <ApolloProvider>
            <BrowserRouter>{children}</BrowserRouter>
            <Notification />
          </ApolloProvider>
        </StoreProvider>
      </Auth0Provider>
      <CssBaseline />
    </ThemeProvider>
  )
}

export default memo(Providers)
