import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { GeneralError } from './Notification'

type LoadingStyles = {
  fullPage: boolean
  error: string | undefined
}
const useStyles = makeStyles(() => ({
  root: ({ fullPage, error }: LoadingStyles) => ({
    display: 'flex',
    height: fullPage && !error ? '80vh' : '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 100,
    minWidth: 100,
  }),
}))

type LoadingTypes = {
  text?: string | React.ReactElement | boolean
  fullPage?: boolean
  error?: string
  size?: number
  rest?: object
}
const Loading = (props?: LoadingTypes): React.ReactElement => {
  const text = props?.text
  const fullPage = props?.fullPage || true
  const error = props?.error
  const size = props?.size || 96
  const classes = useStyles({ fullPage, error })
  return (
    <Container maxWidth='sm' className={classes.root}>
      {text && <Typography gutterBottom>{text}</Typography>}
      {error ? <GeneralError /> : <CircularProgress color='primary' size={size} thickness={1} />}
    </Container>
  )
}

export default Loading
