export const ENVIRONMENT = process.env.ENVIRONMENT || ''
// App
export const HOST = process.env.REACT_APP_TESTPREP_SME_WEB_HOST || ''
export const PORT = process.env.REACT_APP_TESTPREP_SME_WEB_PORT || ''

// Auth0 config
export const AUTH0_DOMAIN = process.env.REACT_APP_TESTPREP_SME_WEB_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_TESTPREP_SME_WEB_AUTH0_CLIENT_ID || ''
export const AUTH0_API_IDENTIFIER = process.env.REACT_APP_TESTPREP_SME_WEB_AUTH0_API_IDENTIFIER || ''

export const API_ENDPOINT = process.env.REACT_APP_TESTPREP_SME_WEB_API_ENDPOINT || ''
