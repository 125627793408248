import React, { ReactNode } from 'react'
import { ApolloClient, ApolloProvider as DefaultApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { API_ENDPOINT } from '../settings'
import { useUserAccessToken } from './hooks'
import { setContext } from '@apollo/client/link/context'

interface ThemeGraphQLProviderPropsInterface {
  children: ReactNode
}

const ApolloProvider = ({ children }: ThemeGraphQLProviderPropsInterface) => {
  const { accessToken } = useUserAccessToken()

  const httpLink = new HttpLink({
    uri: API_ENDPOINT,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${{
            ...extensions,
          }}`,
        )
      })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })
  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  return <DefaultApolloProvider client={apolloClient}>{children}</DefaultApolloProvider>
}

export default ApolloProvider
