import React, { lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
const DashboardPage = lazy(() => import('./dashboard'))
const QuizCreate = lazy(() => import('./quiz/create'))
const QuizList = lazy(() => import('./quiz/list'))
const QuizEdit = lazy(() => import('./quiz/edit'))
const QuizQualityControl = lazy(() => import('./quiz/qualityControl'))

const Pages: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/' component={DashboardPage} />
      <Route exact path='/quiz/create' component={QuizCreate} />
      <Route exact path='/quiz/list' component={QuizList} />
      <Route exact path='/quiz/edit/:quizId' component={QuizEdit} />
      <Route exact path='/quiz/qc/subject/:subjectId' component={QuizQualityControl} />
      <Redirect to='/' />
    </Switch>
  )
}

export default Pages
