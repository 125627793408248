import { Color } from '@material-ui/lab/Alert'
import { useReducer, useCallback, ReactElement } from 'react'
import { createContainer } from 'react-tracked'

export const NOTIFICATION = 'notification'
export const USER_INFO = 'userInfo'
export const QUIZ_CREATION_QUESTION = 'quizCreationQuestion'
export const QUIZ_CREATION_OPTIONS = 'quizCreationOptions'
export const QUIZ_CREATION_CORRECT_ANSWER = 'quizCreationCorrectAnswer'
export const QUIZ_CREATION_TOPIC = 'quizCreationTopic'
export const QUIZ_CREATION_SOLUTION = 'quizCreationSolution'
export const QUIZ_CREATION = 'quizCreation'
export const QUIZ_UPDATE = 'quizUpdate'

export type storeTypes = keyof StatePropsInterface

interface StatePropsInterface {
  [USER_INFO]: UserInfoInterface
  [NOTIFICATION]: NotificationInterface
  [QUIZ_CREATION_QUESTION]: QuizCreationQuestionInterface
  [QUIZ_CREATION_SOLUTION]: QuizCreationSolutionnterface
  [QUIZ_CREATION_OPTIONS]: QuizCreationOptionInterface[]
  [QUIZ_CREATION_CORRECT_ANSWER]: QuizCreationCorrectAnswerInterface
  [QUIZ_CREATION]: QuizCreation
  [QUIZ_CREATION_TOPIC]: QuizCreationTopic
  [QUIZ_UPDATE]: QuizUpdate
}

export interface QuizUpdate {
  id?: number
  status: QuizMutationStatus
  error?: string
}

export interface QuizCreationTopic {
  id?: number
  value?: string
}

export enum QuizMutationStatus {
  START,
  ERROR,
  FINISH,
}
export interface QuizCreation {
  status: QuizMutationStatus
}
export interface QuizCreationCorrectAnswerInterface {
  correctAnswer: number
}

export interface QuizCreationOptionInterface {
  id: number
  content: string
}

export interface QuizCreationQuestionInterface {
  content: string
}

export interface QuizCreationSolutionnterface {
  content: string
}

export interface UserInfoInterface {
  userId?: string
}

export interface NotificationInterface {
  message?: string | ReactElement
  level?: Color
  timeout: number
  onCloseCallback?: () => void
  inPage: boolean
  noClose: boolean
}

export interface Answers {
  answer1?: string
  answer2?: string
  answer3?: string
  answer4?: string
}

export type storePayload =
  | UserInfoInterface
  | QuizCreationOptionInterface
  | QuizCreationQuestionInterface
  | QuizCreationSolutionnterface
  | QuizCreationCorrectAnswerInterface
  | QuizCreation
  | QuizCreationTopic
  | QuizCreationOptionInterface[]
  | QuizUpdate

type Action = {
  type: storeTypes
  payload: storePayload
}

const reducer = (state: StatePropsInterface, action: Action): StatePropsInterface => {
  const { type, payload } = action
  if (type === QUIZ_CREATION_OPTIONS) {
    if (Array.isArray(payload)) {
      return {
        ...state,
        [QUIZ_CREATION_OPTIONS]: payload,
      }
    }
    const quizOptionPayload = payload as QuizCreationOptionInterface
    return {
      ...state,
      [QUIZ_CREATION_OPTIONS]: state[QUIZ_CREATION_OPTIONS].map((option) => {
        return option.id === quizOptionPayload.id ? { ...option, content: quizOptionPayload.content } : option
      }),
    }
  }
  return {
    ...state,
    [type]: {
      ...state[type],
      ...payload,
    },
  }
}

export const userInfoInitialState: UserInfoInterface = {
  userId: undefined,
}

export const notificationInitialState: NotificationInterface = {
  message: undefined,
  timeout: 0,
  inPage: false,
  noClose: false,
}

export const quizCreationOptionsInitialState: QuizCreationOptionInterface[] = [1, 2, 3, 4].map((id) => {
  return {
    id,
    content: '',
  }
})

export const quizCreationQuestionInitialState = {
  content: '',
}

export const quizCreationSolutionInitialState = {
  content: '',
}

export const quizCreationCorrectAnswerInitialState = {
  correctAnswer: 1,
}

export const quizCreationInitialState = {
  status: QuizMutationStatus.START,
}

export const quizCreationTopicInitialState = {
  id: undefined,
  value: undefined,
}

export const quizUpdateInitialState = {
  id: undefined,
  status: QuizMutationStatus.START,
  error: undefined,
}

export const initialState: StatePropsInterface = {
  [USER_INFO]: userInfoInitialState,
  [NOTIFICATION]: notificationInitialState,
  [QUIZ_CREATION_QUESTION]: quizCreationQuestionInitialState,
  [QUIZ_CREATION_SOLUTION]: quizCreationSolutionInitialState,
  [QUIZ_CREATION_CORRECT_ANSWER]: { correctAnswer: 1 },
  [QUIZ_CREATION_OPTIONS]: quizCreationOptionsInitialState,
  [QUIZ_CREATION]: quizCreationInitialState,
  [QUIZ_CREATION_TOPIC]: quizCreationTopicInitialState,
  [QUIZ_UPDATE]: quizUpdateInitialState,
}

const useValue = () => useReducer(reducer, initialState)
export const { Provider, useTracked, useUpdate, useTrackedState } = createContainer(useValue)

export const useDispatchByName = (name: storeTypes) => {
  const dispatch = useUpdate()
  const update = useCallback(
    (newVal: object) => {
      dispatch({
        type: name,
        payload: newVal,
      })
    },
    [dispatch, name],
  )
  return update
}
