import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    '& .MuiDialogContent-root:first-child': {
      padding: 0,
    },
  },
  dialogActionsRoot: {
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  dialogActionButtons: {
    margin: 0,
    padding: 0,
  },
  dialog: {
    padding: 0,
    margin: 0,
    borderRadius: theme.spacing(3),
  },
  item: {
    padding: 0,
    margin: 0,
  },
}))

type DialogPopupProps = {
  isOpen: boolean
  onClose: () => void
  dialogTitle?: string
  dialogContent?: React.ReactElement
  dialogActions?: React.ReactElement
  dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  className: string
}
const DialogPopup = ({
  isOpen,
  onClose,
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogWidth,
  className,
}: DialogPopupProps) => {
  const classes = useStyles()
  return (
    <Dialog
      className={clsx(className, classes.root)}
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        className: classes.dialog,
      }}
      maxWidth={dialogWidth || 'xs'}
    >
      {dialogTitle && (
        <DialogTitle className={classes.item} disableTypography>
          {dialogTitle}
        </DialogTitle>
      )}
      {dialogContent && <DialogContent className={classes.item}>{dialogContent}</DialogContent>}
      {dialogActions && (
        <DialogActions className={clsx(classes.item, classes.dialogActionsRoot)}>{dialogActions}</DialogActions>
      )}
    </Dialog>
  )
}
export default DialogPopup
