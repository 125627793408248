import { useEffect, useMemo } from 'react'
import { useDispatchByName, NOTIFICATION, QuizCreationOptionInterface, Answers } from './store'
import { useAuth } from 'use-auth0-hooks'
import { AUTH0_API_IDENTIFIER } from '../settings'
import { useLocation } from 'react-router-dom'

export const useNotification = () => {
  return useDispatchByName(NOTIFICATION)
}

export const useUserAccessToken = () => {
  const { user, isLoading, accessToken, isAuthenticated, login, logout } = useAuth({
    audience: AUTH0_API_IDENTIFIER,
    scope: 'testprep:create:quiz',
  })
  return {
    login,
    user,
    isLoading,
    accessToken,
    isAuthenticated,
    logout,
  }
}

export const useURLQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useMathJaxTypeSet = (depContent: unknown) => {
  useEffect(() => {
    if (window?.MathJax) {
      window.MathJax.typeset()
    }
  }, [depContent])
}

export const useMapOptionsToAnswers = (options: QuizCreationOptionInterface[]) => {
  return useMemo(() => {
    const answers: Answers = {}
    for (const option of options) {
      answers[`answer${option.id}` as keyof Answers] = option.content
    }
    return answers
  }, [options])
}

export const useValidateQuizForm = (question: string, solution: string, options: QuizCreationOptionInterface[]) => {
  return useMemo(() => {
    if (!question || !solution) {
      return false
    }
    for (const option of options) {
      if (!option.content) {
        return false
      }
    }
    return true
  }, [question, solution, options])
}
