// import { createMuiTheme } from '@material-ui/core/styles' // todo upgrade mui to v5.0
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles'

const sfUiDisplayFonts = [
  {
    name: 'ultralight',
    weight: 200,
  },
  {
    name: 'thin',
    weight: 300,
  },
  {
    name: 'light',
    weight: 400,
  },
  {
    name: 'medium',
    weight: 500,
  },
  {
    name: 'semibold',
    weight: 600,
  },
  {
    name: 'bold',
    weight: 700,
  },
  {
    name: 'heavy',
    weight: 800,
  },
  {
    name: 'black',
    weight: 900,
  },
]

const sfUiDisplay: CSSProperties[] = sfUiDisplayFonts.map((font) => ({
  fontFamily: '"SF UI Display"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: font.weight,
  src: `url('/fonts/sf-ui-display-${font.name}.woff') format('woff')`,
}))

const theme = createMuiTheme({
  typography: {
    fontFamily: '"SF UI Display", Roboto, "Helvetica Neue", Arial, sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 600, // semibold
      lineHeight: '54px',
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 400, // regular
      lineHeight: '56px',
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 500, // medium
      lineHeight: '42px',
    },
    h4: {
      fontSize: '2.25rem',
      fontWeight: 400, // regular
      lineHeight: '42px',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 600, // semibold
      lineHeight: '30px',
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 400, // regular
      lineHeight: '30px',
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 400, // regular
      lineHeight: '26px',
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 300, // light
      lineHeight: '26px',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400, // regular
      lineHeight: '20px',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 300, // light
      lineHeight: '20px',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 500, // medium
      lineHeight: '20px',
      height: '48px',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 300, // light
      lineHeight: '20px',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 300, // light
      lineHeight: '32px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': sfUiDisplay,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#828894',
        color: 'rgba(255, 255, 255, 0.87)',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      outlined: {
        border: '1px solid transparent',
      },
    },
  },
  palette: {
    primary: {
      main: '#FF9A37',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#538DFF',
    },
    text: {
      primary: '#555555',
    },
    background: {
      default: '#F4F5F9',
      paper: '#FFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // phone
      md: 960, // ipad
      lg: 1440, // laptop
      xl: 1920,
    },
  },
})

export default theme
